import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO: React.FC<{
  description?: string | undefined;
  lang?: string;
  meta?: any;
  title?: string | undefined;
  tags?: string[];
  isRoot?: boolean;
}> = ({ description, title, lang, meta, tags, isRoot }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  );
  const metaDescription =
    description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        isRoot
          ? defaultTitle
          : defaultTitle
          ? `%s | ${defaultTitle}`
          : undefined
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: tags
            ? tags.map((x) => x.toUpperCase()).join(', ')
            : 'Blog',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
